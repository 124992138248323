<template>
  <div>
    <v-row>
      <v-col>
        <GlobalStats />
      </v-col>
    </v-row>

    <v-row v-if="$vuetify.breakpoint.mobile">
      <v-col>
        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab v-if="seasonLeaderboard">
            Seasonal Leaders
          </v-tab>
          <v-tab v-else>
            Global Leaders
          </v-tab>
          <v-tab>
            Regional Leaders
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tab"
        >
          <v-tab-item>
            <Loading
              v-if="profileLoading && !leaders.length"
              :loading="profileLoading"
              message="Loading Leaderboard"
            />
            <LeaderList
              v-else
              max-height="450px"
              :entry-list="leaders"
              :load-more="true"
              :season="seasonLeaderboard"
            />
          </v-tab-item>
          <v-tab-item>
            <LeaderList
              max-height="450px"
              :entry-list="regionList"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col>
        <v-card>
          <v-card-title v-if="seasonLeaderboard">
            Seasonal Leaders
          </v-card-title>
          <v-card-title v-else>
            Global Leaders
          </v-card-title>
          <Loading
            v-if="profileLoading && !leaders.length"
            :loading="profileLoading"
            message="Loading Leaderboard"
          />
          <LeaderList
            v-else
            max-height="450px"
            :entry-list="leaders"
            :load-more="true"
            :season="seasonLeaderboard"
          />
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Region Leaders</v-card-title>
          <LeaderList
            max-height="450px"
            :entry-list="regionList"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import GlobalStats from '@/components/GlobalStats.vue'
import LeaderList from '@/components/LeaderList.vue'
import Loading from '@/components/Loading.vue'
import sampleSize from 'lodash.samplesize'

export default {
  components: {
    GlobalStats,
    LeaderList,
    Loading,
  },
  data: function() {
    return {
      polling: null,
      tab: null,
    }
  },
  computed: {
    ...mapState({
      profileList: state => state.profiles.all,
      profileLoading: state => state.profiles.loading,
      seasonLeaderboard: state => state.seasons.seasonLeaderboard,
    }),
    ...mapGetters({
      regionList: 'regions/orderByPoints',
    }),
    leaders() {
      if (this.seasonLeaderboard) {
        if (this.seasonLeaderboard.profiles.length) {
          return this.seasonLeaderboard.profiles.slice(0, 10)
        } else {
          // If there are no season leaders, return a random 10.
          const sample = sampleSize(this.profileList, 10);
          for (let i = 0; i < sample.length; i++) {
            sample[i].points_earned = 0;
          }
          return sample
        }
      } else {
        return this.profileList.slice(0, 10)
      }
    },
  },
  created: function () {
    this.updateStats()
    this.autoRefresh()
  },
  beforeDestroy: function() {
    clearInterval(this.polling)
  },
  methods: {
    updateStats() {
      this.$store.dispatch('stats/getAll')
      this.$store.dispatch('profiles/getAll')
      this.$store.dispatch('regions/getAll')
      this.$store.dispatch('seasons/getAll')
    },
    autoRefresh() {
      this.polling = setInterval(() => {
        this.updateStats()
      }, 1*60*1000)
    },
  },
}
</script>
